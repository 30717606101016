import React from "react";

function About() {
  return (
    <div id="about" className="max-w-[1253px] pt-[110px] pb-[80px] mx-auto">
      <div className="flex w-full gap-[30px] flex-col px-4 xl:flex-row items-center justify-between">
        <img src="/images/about-img.png" alt="" />
        <div className="flex flex-col items-center justify-center">
          <p className="text-[35px] font-extrabold text-black mb-[34px]">
            About <span className="text-[#4CA1DB]">La Plume</span>
          </p>
          <p className="text-center text-[20px] text-[#3A3838] mb-[34px]">
            As seasoned professionals with over six years of experience in
            social media marketing and branding we have had the privilege of
            collaborating with numerous agencies to help them grow their models'
            OnlyFans businesses.{" "}
          </p>
          <p className="text-center text-[20px] text-[#3A3838] mb-[34px]">
            Based in France, our agency is committed to providing high-quality
            social media marketing and branding services to models seeking to
            scale their OnlyFans businesses. With our extensive industry
            expertise and proven track record, we are confident in our ability
            to help models build a strong online presence and achieve their
            business objectives.
          </p>
          <p className="text-center text-[20px] text-[#3A3838] mb-[34px]">
            We look forward to working with aspiring models alike to help them
            achieve their goals and elevate their brand presence. Thank you for
            considering La Plume, for your social media marketing and branding
            needs.
          </p>
          <a
            href="#contact"
            className="w-[154px] h-[50px] mt-[40px] mx-auto flex flex-col items-center justify-center text-white rounded-full bg-[#1C2023]"
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
