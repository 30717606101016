import React from "react";

function Services() {
  return (
    <div
      id="services"
      className="w-full pt-[100px] pb-[40px] z-[2] relative h-full services-bg"
    >
      <div className="max-w-[1253px] mx-auto ">
        <h6 className="font-extrabold text-white text-[35px] text-center mx-auto">
          What We Do
        </h6>
        <div className="flex flex-wrap gap-[20px] px-4 items-start justify-center mt-[44px]">
          <div className="lg:w-[290px] w-full h-full  rounded-[20px] bg-white flex flex-col items-start px-[32px] py-[24px]">
            <img src="/images/icon-1.png" alt="" />
            <p className="font-bold text-[24px] text-black mb-[24px] mt-[16px] text-left">
              OnlyFans Account <br /> Management
            </p>
            <p className="text-[16px] text-[#626262] text-left">
              Unlock the full potential of your OnlyFans content and maximize
              your earnings with our expert Account Management service.
            </p>
          </div>
          <div className="lg:w-[290px] w-full h-full  rounded-[20px] bg-white flex flex-col items-start px-[32px] py-[24px]">
            <img src="/images/icon-2.png" alt="" />
            <p className="font-bold text-[24px] text-black mb-[24px] mt-[16px] text-left">
              OnlyFans Account <br /> Growth
            </p>
            <p className="text-[16px] text-[#626262] text-left">
              Experience rapid and organic growth of your OnlyFans account like
              never before with our proven strategies and dedicated Account
              Growth service.
            </p>
          </div>
          <div className="lg:w-[290px] w-full h-full rounded-[20px] bg-white flex flex-col items-start px-[32px] py-[24px]">
            <img src="/images/icon-3.png" alt="" />
            <p className="font-bold text-[24px] text-black mb-[24px] mt-[16px] text-left">
              Content Reach <br /> Marketing
            </p>
            <p className="text-[16px] text-[#626262] text-left">
              Expand your OnlyFans content's reach and captivate a wider
              audience with our specialized Content Reach Marketing solutions.
            </p>
          </div>
          <div className="lg:w-[290px] w-full h-full  rounded-[20px] bg-white flex flex-col items-start px-[32px] py-[24px]">
            <img src="/images/icon-4.png" alt="" />
            <p className="font-bold text-[24px] text-black mb-[24px] mt-[16px] text-left">
              Financial & Future <br /> Advising
            </p>
            <p className="text-[16px] text-[#626262] text-left">
              Secure your financial future and make informed decisions with our
              comprehensive Financial & Future Advising services tailored
              specifically for OnlyFans creators.
            </p>
          </div>
        </div>
        <a
          href="#contact"
          className="w-[154px] h-[50px] mt-[40px] mx-auto flex flex-col items-center justify-center text-white rounded-full bg-[#1C2023]"
        >
          Get Started
        </a>
      </div>
    </div>
  );
}

export default Services;
