import React from "react";

function Header() {
  return (
    <div className="pt-[50px] relative">
      <div className="max-w-[1253px] mx-auto flex flex-col xl:flex-row items-center">
        <div className="flex flex-col px-4 items-center lg:items-start">
          <h1 className="text-black text-[42px] leading-[3rem] md:leading-[4rem] md:text-[56px] font-extrabold lg:text-left text-center mb-[54px]">
            Level up your <br />{" "}
            <span className="text-[#4CA1DB]">OnlyFans </span>
            content <br /> career instantly
          </h1>
          <p className="max-w-[525px] lg:text-left text-center text-[#626262] text-[20px] mb-[50px]">
            Sign with La Plume and let us write the next chapter in your
            OnlyFans success story.
          </p>
          <a
            href="#contact"
            className="w-[154px] h-[50px] flex flex-col items-center justify-center text-white rounded-full bg-[#1C2023]"
          >
            Get Started
          </a>
        </div>
        <img
          className="lg:max-w-[700px] max-w-[95%] "
          src="/images/header-img.png"
          alt=""
        />
      </div>
      {/* <img
        className="sm:absolute hidden  right-[-2.5%] z-[1] max-w-[50%]  top-[40px] 2xl:hidden"
        src="/images/header-img.png"
        alt=""
      /> */}
    </div>
  );
}

export default Header;
